import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '../views/pages/auth/routes'
import attendanceRoutes from '../views/pages/attendance/routes'
import collectRoutes from '../views/pages/collect/routes'
import consultExamsRoutes from '../views/pages/consultExams/routes'
import sellExamsRoutes from '../views/pages/sellExams/routes.js'
import sendDocRoutes from '../views/pages/send-doc/routes'
import trainingRoutes from '../views/pages/training/routes'
import logisticsRoutes from '../views/pages/logistics/routes'
import orderKitsRoutes from '../views/pages/orderKits/routes'
import homeRoutes from '../views/pages/home/routes'
import postsRoutes from '../views/pages/posts/routes'
import receiveRoutes from '../views/pages/receive/routes'
import receiveApoioRoutes from '../views/pages/receiveApoio/routes'
import infoContributorsRoutes from '../views/pages/infoContributors/routes'
import registrationUpdateRoutes from '../views/pages/registrationUpdate/routes'
import billsPayRoutes from '../views/pages/billsPay/routes'
import billsPayApoioRoutes from '../views/pages/billsPayApoio/routes'
import unityRoutes from '../views/pages/unity/routes'
import prizesRoutes from '../views/pages/prizes/routes'
import pendenciesRoutes from '../views/pages/pendencies/router'
import requestSampleRoutes from '../views/pages/requestSample/routes'
import adminLabetRoutes from '../views/pages/adminlabet/routes'
import updatePasswordRoutes from '../views/pages/update-password/routes'
import admPostRoutes from '../views/pages/adm-post/routes'
import contractRoutes from '../views/pages/contract/route'
import pixRoutes from '../views/pages/pix/route'
import consultLabsRoutes from '../views/pages/consultLabs/route'
import revendaRoutes from '../views/pages/revenda/route'
import consultExamsRevendaRoutes from '../views/pages/consultExamsRevenda/route'

import moment from 'moment'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/auth',
  },
  {
    path: '/receive-apoio',
    ...receiveApoioRoutes,
  },
  {
    path: '/bills-to-pay-apoio',
    ...billsPayApoioRoutes,
  },
  {
    path: '/sendDoc',
    ...sendDocRoutes,
  },
  {
    path: '/pix',
    ...pixRoutes,
  },
  {
    path: '/revenda',
    ...revendaRoutes,
  },
  {
    path: '/consultExamsRevenda',
    ...consultExamsRevendaRoutes,
  },
  {
    path: '/consultLabs',
    ...consultLabsRoutes,
  },
  {
    path: '',
    redirect: '/auth',
  },
  {
    path: '/LabetCFC',
    redirect: '/LabetCFC',
  },
  {
    path: '/adm-post',
    ...admPostRoutes,
  },
  {
    path: '/update-password',
    ...updatePasswordRoutes,
  },
  {
    path: '/adminlabet',
    ...adminLabetRoutes,
  },
  {
    path: '/auth',
    ...authRoutes,
  },
  {
    path: '/requestSample',
    ...requestSampleRoutes,
  },
  {
    path: '/attendance',
    ...attendanceRoutes,
  },
  {
    path: '/consultExams',
    ...consultExamsRoutes,
  },
  {
    path: '/sellExams',
    ...sellExamsRoutes,
  },
  {
    path: '/collect',
    ...collectRoutes,
  },
  {
    path: '/training',
    ...trainingRoutes,
  },
  {
    path: '/pendencies',
    ...pendenciesRoutes,
  },
  {
    path: '/logistics',
    ...logisticsRoutes,
  },
  {
    path: '/orderKits',
    ...orderKitsRoutes,
  },
  {
    path: '/home',
    ...homeRoutes,
  },
  {
    path: '/post',
    ...postsRoutes,
  },
  {
    path: '/receive',
    ...receiveRoutes,
  },
  {
    path: '/info-contributors',
    ...infoContributorsRoutes,
  },
  {
    path: '/registration-update',
    ...registrationUpdateRoutes,
  },
  {
    path: '/bills-to-pay',
    ...billsPayRoutes,
  },
  {
    path: '/unity',
    ...unityRoutes,
  },
  {
    path: '/prizes',
    ...prizesRoutes,
  },

  {
    path: '/contract',
    ...contractRoutes,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to == from) {
    return
  }

  let token = localStorage.getItem('token')
  let expiresToken = localStorage.getItem('validTo')
  let user = JSON.parse(localStorage.getItem('user'))

  if (to.meta.requiresAuth) {
    if (token) {
      if (moment().isBefore(moment(expiresToken, 'yyyy-MM-DD'))) {
        next()
      } else {
        next('/auth')
      }
    } else {
      next('/auth')
    }
  } else {
    next()
  }
})

export default router
