import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#354366",
        secondary: "#D76C12",
        terciary: "#34405f",
        quaternary: "#546BA5",
      },
      light: {
        primary: "#354366",
        secondary: "#D76C12",
        terciary: "#34405f",
        quaternary: "#546BA5",
      },
    },
  },
});
