const layout = () => import("../../internal/layout.vue");
const orderKits = () => import("./orderKits.vue");

const orderKitsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "orderKits",
      meta: {
        title: "LOGÍSTICA - GESTÃO DE KITS",
        requiresAuth: true,
      },
      component: orderKits,
    },
  ],
};

export default orderKitsRoutes;
