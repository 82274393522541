const layout = () => import("../../internal/layout.vue");
const infoContributors = () => import("./infoContributors.vue");

const infoContributorsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "info-contributors",
      meta: {
        title: "INFORMAÇÕES DO PCL - COLABORADORES",
        requiresAuth: true,
      },
      component: infoContributors,
    },
  ],
};

export default infoContributorsRoutes;
