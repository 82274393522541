const layout = () => import('../../internal/layout.vue')
const sendDoc = () => import('./index.vue')

const sendDocRoutes = {
  component: layout,
  children: [
    {
      path: '',
      name: 'sendDoc',
      meta: {
        title: 'ENVIO DE DOCUMENTOS',
        requiresAuth: true,
      },
      component: sendDoc,
    },
  ],
}

export default sendDocRoutes
