const layout = () => import("../../internal/layout.vue");
const registrationUpdate = () => import("./registrationUpdate.vue");

const registrationUpdateRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "registration-update",
      meta: {
        title: "INFORMAÇÕES DO PCL - ATUALIZAÇÃO CADASTRAL",
        requiresAuth: true,
      },
      component: registrationUpdate,
    },
  ],
};

export default registrationUpdateRoutes;
