const layout = () => import("../../internal/layout.vue");
const updatePassword = () => import("./index.vue");

const updatePasswordRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "updatePassword",
      meta: {
        title: "PERFIL - ATUALIZAR SENHA",
        requiresAuth: true,
      },
      component: updatePassword,
    },
  ],
};

export default updatePasswordRoutes;
