const layout = () => import('../../internal/layout.vue')
const billsPay = () => import('./billsPay.vue')

const billsPayRoutes = {
  component: layout,
  children: [
    {
      path: '',
      name: 'bills-to-pay-apoio',
      meta: {
        title: 'FINANCEIRO - CONTAS A PAGAR (APOIO)',
        requiresAuth: true,
      },
      component: billsPay,
    },
  ],
}

export default billsPayRoutes
