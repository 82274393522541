const layout = () => import("../../internal/layout.vue");
const receive = () => import("./receive.vue");


const receiveRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "receive",
      meta: {
        title: "FINANCEIRO - CONTAS A RECEBER",
        requiresAuth: true,
      },
      component: receive
    },
  ],
};

export default receiveRoutes;
