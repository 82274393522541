const layout = () => import("../../internal/layout.vue");
const billsPay = () => import("./billsPay.vue");

const billsPayRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "bills-to-pay",
      meta: {
        title: "FINANCEIRO - CONTAS A PAGAR",
        requiresAuth: true,
      },
      component: billsPay,
    },
  ],
};

export default billsPayRoutes;
