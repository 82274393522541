
const layout = () => import("../../internal/layout.vue");
const posts = () => import("./posts.vue");

const postsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "posts",
      meta: {
        title: "FIQUE POR DENTRO",
        requiresAuth: true,
      },
      component: posts,
    },
  ],
};

export default postsRoutes;
