const layout = () => import("../../external/layout.vue");
const index = () => import("./index.vue");

const adminLabetRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "adminLabet",
      meta: {
        title: "Administrador",
      },
      component: index,
    },
  ],
};

export default adminLabetRoutes;
