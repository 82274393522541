const layout = () => import("../../internal/layout.vue");
const requestSample = () => import("./index.vue");

const requestSampleRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "requestSample",
      meta: {
        title: "LOGÍSTICA - SOLICITAR RETIRADA DE AMOSTRA",
        requiresAuth: true,
      },
      component: requestSample,
    },
  ],
};

export default requestSampleRoutes;
