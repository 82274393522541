const layout = () => import("../../internal/layout.vue");
const attendance = () => import("./attendance.vue");

const attendanceRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "attendance",
      meta: {
        title: "COMPRAR EXAME TOXICOLÓGICO",
        requiresAuth: true,
      },
      component: attendance,
    },
  ],
};

export default attendanceRoutes;
