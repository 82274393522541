const layout = () => import("../../external/layout.vue");
const auth = () => import("./auth.vue");

const authRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "login",
      component: auth,
    },
  ],
};

export default authRoutes;
