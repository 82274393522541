const layout = () => import('../../internal/layout.vue')
const receive = () => import('./receive.vue')

const receiveRoutes = {
  component: layout,
  children: [
    {
      path: '',
      name: 'receive-apoio',
      meta: {
        title: 'FINANCEIRO - CONTAS A RECEBER (APOIO)',
        requiresAuth: true,
      },
      component: receive,
    },
  ],
}

export default receiveRoutes
