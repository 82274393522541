import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import lightmode from './styles/variables-light.json'
import darkmode from './styles/variables-dark.json'
import VueResource from 'vue-resource'
import './styles/styles.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueTheMask from 'vue-the-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
})

const options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  icon: true,
}
Vue.use(VueTheMask)
Vue.use(Toast, options)

Vue.use(VueResource)

Vue.prototype.$customStyleLight = lightmode
Vue.prototype.$customStyleDark = darkmode

Vue.config.productionTip = false

Vue.$globalEvent = new Vue()

// Sentry.init({
//   Vue,
//   dsn: "http://faf6f8e27f0b4325bc788a68b65ba027@20.206.132.74:9000/13",
//   tracesSampleRate: 1.0,
// });

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCXrCVVS8FpPrKxoei039ZkaA1EnmqrMxA', // Substitua pela sua chave
    libraries: 'places',
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    Vue.$globalEvent.$on('httpError', (item) => {
      if (item.body && typeof item.body === 'string') {
        this.$toast.error(item.body)
      } else {
        this.$toast.error('Ocorreu um erro, entre em contato com o atendimento')
      }
    })
  },
}).$mount('#app')
