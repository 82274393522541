const layout = () => import("../../external/layout.vue");
const prizes = () => import("./prizes.vue");

const prizesRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "prizes",
      meta: {
        title: "Posts",
        requiresAuth: true,
      },
      component: prizes,
    },
  ],
};

export default prizesRoutes;
