const layout = () => import("../../external/layout.vue");
const  unity = () => import("./unity.vue");

const  unityRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "unity",
      
      component: unity,
    },
  ],
};

export default unityRoutes;
