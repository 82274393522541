const layout = () => import("../../internal/layout.vue");
const revenda = () => import("./revenda.vue");

const revendaRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "revenda",
      meta: {
        title: "REVENDER EXAME - CLT",
        requiresAuth: true,
      },
      component: revenda,
    },
  ],
};

export default revendaRoutes;