const layout = () => import("../../internal/layout.vue");
const posts = () => import("./adm-post.vue");

const postsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "posts",
      meta: {
        title: "ADMINISTRAR PUBLICAÇÕES",
        requiresAuth: true,
      },
      component: posts,
    },
  ],
};

export default postsRoutes;
