const layout = () => import("../../external/layout.vue");
const consultLabs = () => import("./consultLabs.vue");

const consultLabsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "Consultar Rede",
      component: consultLabs,
    },
  ],
};

export default consultLabsRoutes;
