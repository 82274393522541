const layout = () => import("../../internal/layout.vue");
const training = () => import("./training.vue");

const trainingRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "training",
      meta: {
        title: "TREINAMENTO",
        requiresAuth: true,
      },
      component: training,
    },
  ],
};

export default trainingRoutes;
