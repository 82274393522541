const layout = () => import("../../internal/layout.vue");
const consultExamsRevenda = () => import("./consultExamsRevenda.vue");

const consultExamsRevendaRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "consultExamsRevenda",
      meta: {
        title: "CONSULTAR EXAMES DA REVENDA",
        requiresAuth: true,
      },
      component: consultExamsRevenda,
    },
  ],
};

export default consultExamsRevendaRoutes;