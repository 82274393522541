const layout = () => import("../../internal/layout.vue");
const consultExams = () => import("./consultExams.vue");

const consultExamsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "consultExams",
      meta: {
        title: "CONSULTAR EXAMES",
        requiresAuth: true,
      },
      component: consultExams,
    },
  ],
};

export default consultExamsRoutes;
