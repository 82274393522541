const layout = () => import("../../internal/layout.vue");
const logistics = () => import("./logistics.vue");

const logisticsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "logistics",
      meta: {
        title: "LOGÍSTICA - ACOMPANHAR ENVIO DE AMOSTRAS",
        requiresAuth: true,
      },
      component: logistics,
    },
  ],
};

export default logisticsRoutes;
