const layout = () => import("../../internal/layout.vue");
const pendencies = () => import("./index.vue");

const pendenciesRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "pendencies",
      meta: {
        title: "PENDÊNCIAS",
        requiresAuth: true,
      },
      component: pendencies,
    },
  ],
};

export default pendenciesRoutes;
