const layout = () => import("../../external/layout.vue");
const pix = () => import("./pix.vue");

const pixRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "pix",
      component: pix,
    },
  ],
};

export default pixRoutes;
