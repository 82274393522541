const layout = () => import("../../internal/layout.vue");
const home = () => import("./home.vue");

const homeRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "home",
      meta: {
        title: "HOME",
        requiresAuth: true,
      },
      component: home,
    },
  ],
};

export default homeRoutes;
