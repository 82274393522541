const layout = () => import("../../internal/layout.vue");
const sellExams = () => import("./sellExams.vue");

const sellExamsRoutes = {
  component: layout,
  children: [
    {
      path: "",
      name: "sellExams",
      meta: {
        title: "COMPRAR EXAME TOXICOLÓGICO",
        requiresAuth: true,
      },
      component: sellExams,
    },
  ],
};

export default sellExamsRoutes;
