<template>
  <v-app :style="this.$customStyleLight">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.footer {
  min-height: 60px;

  background: var(--v-mainBackground-base);
  border-radius: 4px;

  padding: 6px 20px;
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--v-tableFontColor-base) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
</style>
