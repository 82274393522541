const layout = () => import("../../internal/layout.vue");
const collect = () => import("./collect.vue");

const collectRoutes = {
  component: layout,
  children: [
    {
      path: "/",
      name: "collect",
      meta: {
        title: "REGISTRO ELETRONICA DA COLETA",
        requiresAuth: true,
      },
      component: collect,
    },
  ],
};

export default collectRoutes;
